<!-- Pendiente Documentación -->

<template>
  <div class="fras_PteDoc mr-1">   
    <v-dialog v-model="dialog" content-class="modal" persistent> 
      <template v-slot:activator="{on}">

        <!-- Botón para abrir Finder Documentos -->
        <v-btn 
          :color="!show? '' : pendiente ? 'red lighten-3' : 'green lighten-3'"
          v-on='on'
          @click="datos()"
          v-bind="$cfg.btra_cfg.standard"          
          :title="modulo"
          :disabled="!show">
            PTE
        </v-btn>       
      </template>

      <div class="contenedor centrado">
        <v-sheet :elevation="4">

          <!-- Cabecera -->
          <div class="cabecera">
            <base_Header
              v-if="Entorno.header.header"       
              :Entorno="Entorno.header"
              @onEvent="dialog=false">
            </base_Header>
          </div> 
                    
          <div class="conflex pl-2 pt-2 pb-2" style="width:600px;">
            <v-btn 
              v-bind="$cfg.btra_cfg.standard"
              :disabled="!edicion"
              @click="guardar()">
                <v-icon>{{'mdi-content-save'}}</v-icon>
            </v-btn> 
          </div>

          <div class="conflex pl-2">
            <div class="columna" style="width:100%">
                <div class="cab">Expediente</div>
                <v-sheet v-bind="$cfg.styles.marco"> 
                    <div class="columna">
                      <div class="conflex">
                        <vllabel
                            style="flex: 0 0 45%" 
                            label="Control"
                            :txt="auxRecord.control">              
                        </vllabel>
                        <vllabel
                            style="flex: 0 0 55%" 
                            label="Referencia"
                            :txt="auxRecord.callref1">              
                        </vllabel>
                      </div>
                      <vllabel
                        style="flex: 0 0 100%" 
                        label="Fallecido"
                        :txt="auxRecord.fallename">              
                      </vllabel>
                      <!-- <vllabel
                        style="width:100%" 
                        label="Compañia"
                        :txt="auxRecord.name_callemp">              
                      </vllabel> -->
                    </div>
                </v-sheet> 
            </div> 
            </div>
            <div class="conflex pl-2">
              <div class="columna" style="width:100%;">
                <div class="cab">Factura</div>
                <v-sheet v-bind="$cfg.styles.marco">
                    <div class="columna">
                      <div class="conflex">
                         <vllabel
                            style="width:20%;" 
                            label="Tipo"
                            :txt="FRA.tipo_fra">              
                        </vllabel>
                        <vllabel
                            style="width:50%;" 
                            label="Número"
                            :txt="FRA.fra_doc">              
                        </vllabel>
                        <vllabel
                            style="width:30%;" 
                            label="Fecha"
                            :txt="FRA.fra_fh | fecha">              
                        </vllabel>
                      </div>
                    </div>
                    <div class="columna">                 
                      <vllabel
                        style="width:100%;" 
                        label="Empresa"
                        :txt="FRA.name_cta_id">
                      </vllabel>                      
                    </div>
                </v-sheet> 
            </div> 
          </div>           
          <div class="conflex pl-2">
            <div class="columna" style="width:100%;">
                <div class="cab">Estado / Observaciones</div>
                
                  <v-sheet v-bind="$cfg.styles.marco"> 

                    <div class="conflex">
                      <div class="columna" style="width:80%;">
                        <v-select
                            :style="!cerrado ? 'background-color: rgba(0,150,0,0.1)':''"
                            v-bind="$select"                            
                            v-model="schema.ctrls.ptedoc.value"                                                    
                            :label="schema.ctrls.ptedoc.label"                        
                            :items="$store.state.datos_iniciales.tipo_ptedoc"
                            @change="editamos()"
                            :disabled="cerrado"
                            dense
                            item-value="id"
                            item-text="name">
                        </v-select>
                      </div>
                      <div class="columna" style="width:20%;">
                        <v-checkbox 
                          v-bind="$select"                         
                          v-model="schema.ctrls.pago.value"
                          @change="editamos()"
                          :label="schema.ctrls.pago.label">
                        </v-checkbox>
                      </div>
                    </div>

                    <div v-if="!edicion">
                      <ctrlobs3
                        :schemaComponente="schema.ctrls.ptedoc_obs"
                        :schema="schema"
                        :record="accionRow"
                        :edicion=false>
                      </ctrlobs3>
                    </div>
                    
                    <div v-else>                           
                      <h4 style="color: red">Introduce Comentario / Observaciones y pulsa Guardar</h4>
                      <v-textarea
                        style="background-color: rgba(0,150,0,0.1)"                        
                        v-bind="$textarea"
                        v-model="schema.ctrls.obs.value"
                        :label="schema.ctrls.obs.label"
                        rows="3"
                        no-resize>                      
                      </v-textarea>
                    </div>
                  </v-sheet> 
                </div>
            </div>     
            <!-- </div>         -->
        </v-sheet> 
      </div>
    </v-dialog>    
  </div>
</template>



<script>

  import { mixinMto } from "@/mixins/mixinMto.js";
  import { mixinMtoArray } from "@/mixins/mixinMtoArray.js";  
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const ctrlobs3 = () => plugs.groute("ctrlobs3.vue", "comp");    
      
  export default {
    mixins: [mixinMto, mixinMtoArray],
    components: { base_Header, ctrlobs3 },
    props: {
      padre: { type:String, default: '' }, 
      accion: { type:[Number, String], default: ''},
      auxRecord: { type: Object, default: ()=> {}}, 
      accionRow: { type: Object, default: ()=> {}}, 
      tipo: { type: [String, Number], default: "1" }, 
      show: { type: [String, Boolean], default: "1" },    
      componenteTipo: { type:String, default:'M' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema: {
          ctrls: {
            //mostramos
            ptedoc_obs: { f:'', label:'', value:"",
              comp: { 
                type:"obs3",
                fields:{ gestion:"ptedoc_obs" },
                valueaux:""                            
              }
            },
            //editamos
            ptedoc: { f:'ptedoc', label:'Pendiente Documentación', value:"" },
            pago: { f:'pago', label:'Pago', value:"1" },
            obs: { f:'obs', label:'Comentario', value:"" }
          }
        },
        api:'',
        stName:'stMfras_PteDoc',
        modulo: 'Pendiente Documentación',      
        dialog:false,        

        /* itemsPteDoc: [
          { id:"0", name:"* Correcto *"},
          { id:"1", name:"FACTURA"},
          { id:"2", name:"SUPLIDOS"},
          { id:"3", name:"PTE.JUSTIFICANTE PAGO SUPLIDO"},
          { id:"5", name:"DOCUMENTOS/VARIOS"},          
        ], */

        edicion: false,
      };
    },


    created() {
      this.ini_data();
    },

    computed: {
        FRA() {
          if (this.accionRow==null) return {};

          let R=this.accionRow;
          R.pte=R.imp-R.fra_pago;
          return R;
        },

        cerrado() {
            return this.auxRecord.chkconfcierre=='1';

        },
        pendiente() {                        
            return this.FRA.ptedoc>'0'? true : false;
        },
        
    },
   
    methods: {

      ini_data() {
        
        // configuro Entorno 
        this.Entorno.header.titulo = this.modulo;         

              
      },
      
      record_cargado() {
        //if (this.pendiente) return;
        //this.record2ctrl({}, this.schema.ctrls, true);


      },

      //leemos datos o pasamos a schema.ctrls
      datos() {     
        //datos editables
        //this.schema.ctrls.ptedoc.value= this.accionRow.ptedoc;
        //this.schema.ctrls.ptedoc_obs.value= this.accionRow.ptedoc_obs;             

        this.record2ctrl(this.accionRow, this.schema.ctrls, true);    //preguntar antonio por que no ptedoc  
        
        this.schema.ctrls.ptedoc.value= this.accionRow.ptedoc;  
        this.schema.ctrls.pago.value=(this.accionRow.pago=='1'?true:false);  

        this.edicion=false;
      },

      // guardo datos
      async guardar() {       
        //let ctrls= this.ctrl2record_wo_compare(this.schema.ctrls);
        let args = { 
            tipo:'fnc', accion: 'facturas', 
            fn_args: {           
              accion:'pteDocum', 
              id:this.accionRow.id,
              std:this.schema.ctrls.ptedoc.value,
              pag:(!this.schema.ctrls.pago.value?0:1),
              txt:this.schema.ctrls.obs.value                          
            }
        };
        console.log('args guardar: ', args);
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        console.log('apiResult envio_emailGd: ', apiResult);        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
 
        this.$root.$alert.open('Datos guardados correctamente', 'success', 1800);

        // actualizo record de la fra seleccionada
        //this.$store.commit(this.padre + '/update_record', { opcion: 'guardar', record: apiResult.r[0], id: apiResult.r[0].id });
        this.$emit('reload');
        this.dialog= false;
      },

      editamos() {        
        //si cambia combo o check
        this.edicion=(this.accionRow.ptedoc!=this.schema.ctrls.ptedoc.value || this.accionRow.pago!=this.schema.ctrls.pago.value);

        this.schema.ctrls.obs.value='';
      }

    },

  }
</script>
